<template lang="pug">
.row.q-mt-lg.full-block.justify-between.bg-blue-15.b-radius-16.q-pa-lg.justify-center
    .row(style="max-width:1176px").no-wrap.full-width
        .row.relative-position.justify-center(:class="{'col-6': windowWidth > 961 && windowWidth <= 1285,'col-7': windowWidth > 1285}")
            div.full-width(style="max-width: 555px" :class="{'mobile-hide': windowWidth <= 961 }")
                .relative-position(style="max-width: 357px")
                    img(
                        :src="'/bubleqr.png'"
                    )
                    .absolute(style="top: 16px;right: 0;left: 16px; ")
                        img(
                            :src="'/qr-code.svg'"
                            style="max-width:143px"
                        )
                    .absolute(style="top: 16px;right: 16px; max-width:164px ;transform: translate(7px, 21px);")
                        .font-14.text-primary Наведите камеру на QR-код, чтобы скачать приложение
                        .q-mt-sm.q-mb-md.row(style="gap:0 16px;")
                            a(href="https://play.google.com/store/apps/details?id=artes.tabletochka" target="_blank" rel="nofollow")
                                q-icon(
                                    name="etabl:google-play"
                                    size="sm"
                                    color="positive")
                            a(href="https://apps.apple.com/ru/app/etabl/id1542449495" target="_blank" rel="nofollow")
                                q-icon(
                                    name="etabl:app-store"
                                    size="sm"
                                    color="positive")
                            a(href="https://appgallery.huawei.com/app/C107603961" target="_blank" rel="nofollow")
                                q-icon(
                                    name="etabl:app-gellary"
                                    size="sm"
                                    color="positive")
                            a(href="https://apps.rustore.ru/app/artes.tabletochka" target="_blank" rel="nofollow")
                                q-icon(
                                    name="etabl:ru-store"
                                    size="sm"
                                    color="positive")
            .custom-img(
                :class="{'tablet-hide': windowWidth <= 1285}"
            )
                img(
                    :src="'/mobile_hand.png'"
                )
        .row.full-width(
            :class="[windowWidth <= 961 ? 'col-12' : 'col-6', windowWidth <= 961 ? 'justify-center' : '' ,]"
            :style="{'max-width': windowWidth <= 961 ? '100%' : (windowWidth > 961 && windowWidth <= 1285 ? '450px' : '500px')}"
        )
            .row.column
                .text-primary.text-weight-bold(:class="{'font-32': windowWidth >1285, 'font-28': (windowWidth <= 1285 && windowWidth > 961), 'font-20': windowWidth < 961 }") Скачайте наше приложение!
                .text-primary.text-weight-bold(:class="windowWidth > 961 ? 'font-18' : 'font-16'")
                    | В приложении  
                    span.text-positive etabl  
                    | удобней:
                ul.text-primary.text-weight-regular(:class="[{'font-14' : windowWidth < 961}, {'font-16' : (windowWidth < 1285 && windowWidth > 961)}, {'font-18': windowWidth > 1285} ]")
                    li Узнавайте о скидках и товарах дня первым
                    li Выгодные предложения по доступным ценам
                    li Карта лояльности всегда под рукой
                    li Мониторинг состояния заказов
                .q-mt-sm.q-mb-md.row.justify-center(style="gap:0 16px;" v-if="windowWidth< 961" )
                    a(href="https://play.google.com/store/apps/details?id=artes.tabletochka" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:google-play"
                            size="md"
                            color="positive")
                    a(href="https://apps.apple.com/ru/app/etabl/id1542449495" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:app-store"
                            size="md"
                            color="positive")
                    a(href="https://appgallery.huawei.com/app/C107603961" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:app-gellary"
                            size="md"
                            color="positive")
                    a(href="https://apps.rustore.ru/app/artes.tabletochka" target="_blank" rel="nofollow")
                        q-icon(
                            name="etabl:ru-store"
                            size="md"
                            color="positive")


</template>

<script setup>

import { onMounted, ref } from 'vue';

const windowWidth = ref(0);

const resizeWindow = (value) => {
    windowWidth.value = value;
};

onMounted(() => {
    windowWidth.value = window.innerWidth;
    if (process.env.CLIENT) {
        window.addEventListener('resize', () => {
            windowWidth.value = window.innerWidth;
        });
    }
    ;
});

</script>

<style scoped lang="scss">
.custom-img {
    position: absolute;
    bottom: -24px;
    right: 0;
}

.tablet-hide {
    display: none;
}

.mobile-hide {
    display: none;
}
</style>
